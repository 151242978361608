<template>
  <div class="dashboard workout-by-page">
    <tabs/>
    <div class="box-tab-content">
      <div class="workout-slider-block">
        <workoutsSlider/>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import workoutsSlider from "@/components/workouts/workouts-slider.vue";
import workshopSlider from "@/components/workshop/workshop-slider.vue";
import tabs from "@/components/workouts/workout-by-tabs.vue";

export default {
  name: "workout-by",
  data () {
    return {
      lang: this.$root.siteLang,
      areaItems: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.treinar_por,
    }
  },
  components : {
    workoutsSlider,
    workshopSlider,
    tabs
  }
}
</script>